import React, { useState } from "react";
import { Image } from "lucide-react";

function BrandLogoUpload() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      // 5MB limit
      setSelectedFile(file);
    } else {
      alert("File size must be less than 5MB");
    }
  };

  const styles = getStyles(!!selectedFile);

  return (
    <div style={styles.container}>
      <div style={styles.uploadBox}>
        <div style={styles.iconContainer}>
          <Image size={48} style={styles.icon} />
        </div>

        <div style={styles.textContainer}>
          <p style={styles.mainText}>
            Upload company logo (.jpg .jpeg .png .svg)
          </p>
          <p style={styles.subText}>Max file size 50MB</p>
        </div>

        <label style={styles.inputLabel}>
          <input
            type="file"
            style={styles.hiddenInput}
            accept=".jpg,.jpeg,.png,.svg"
            onChange={handleFileSelect}
          />
          <div style={styles.uploadButton}>Upload Logo</div>
        </label>

        {selectedFile && (
          <div style={styles.fileInfo}>
            File: {selectedFile.name} (
            {(selectedFile.size / (1024 * 1024)).toFixed(2)}MB)
          </div>
        )}
      </div>
    </div>
  );
}

const getStyles = (hasFile) => ({
  container: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
  },
  uploadBox: {
    //backgroundColor: "white",
    borderRadius: "8px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
  },
  iconContainer: {
    width: "80px",
    height: "80px",
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: "#9ca3af",
  },
  textContainer: {
    textAlign: "center",
  },
  mainText: {
    color: "#4b5563",
    marginBottom: "4px",
  },
  subText: {
    color: "#6b7280",
    fontSize: "14px",
  },
  inputLabel: {
    width: "100%",
    cursor: "pointer",
  },
  hiddenInput: {
    display: "none",
  },
  uploadButton: {
    display: "flex",
    margin: "0 auto", // This centers the button horizontally
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically (if needed)
    width: "50%",
    padding: "12px 12px",
    backgroundColor: "#3464eb",
    color: "white",
    textAlign: "center",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "#0f766e",
    },
  },
  fileInfo: {
    width: "100%",
    fontSize: "14px",
    color: "#4b5563",
  },
});

export default BrandLogoUpload;
