import React from "react";
import { Typography } from "@mui/material";

const CardContent = () => {
  return (
    <div>
      <Typography variant="h6" style={{ color: "#000" }}>
        This is the Card tab content.
      </Typography>
    </div>
  );
};

export default CardContent;
