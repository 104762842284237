import { useState } from "react";
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  TableFooter
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { DeleteEmployee } from "../components/delete-employee";
// import { Scrollbar } from 'src/components/scrollbar';
// import { getInitials } from 'src/utils/get-initials';


export const EmployeesTable = (props) => {
  const {
    items = [],
    getRoleById,
    handleEditOpen,
    requesterId,
    onPageChange = () => { },
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    // selected = [],
    fetchData,
    // isAdmin,
  } = props;

  return (
    <Card sx={{ marginLeft: 0, }}>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((customer) => (
              <TableRow hover key={customer.UserId}>
                <TableCell style={{ width: '20%' }}>
                  <div style={{ display: 'flex' }}>
                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      size="small"
                      onClick={() => handleEditOpen(customer)}
                      style={{ cursor: 'pointer', marginRight: '8px' }}
                    >
                      Edit
                    </Button>
                    {customer.Role === 1 && (
                      <DeleteEmployee
                        userId={customer.UserId}
                        requesterId={requesterId}
                        fetchData={fetchData}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Typography variant="subtitle2">
                      {`${customer.FirstName} ${customer.LastName}`}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{customer.EmailAddress}</TableCell>
                <TableCell>{customer.AccountApproved ? "Active" : "Pending"}</TableCell>  {/* Fixed the nesting */}
                <TableCell>{getRoleById(customer.Role)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </Box>
      {/* <TablePagination
        component="div"
        count={items.length}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};


export const AddEmployee = (props) => {
  const {
    setFirstName,
    setLastName,
    setEmail,
    // setPassword,
    addOpen,
    handleAddClose,
    onSendInvite,
  } = props;

  return (
    <Dialog
      open={addOpen}
      onClose={handleAddClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1500px",
          },
        },
      }}
    >
      <DialogTitle style={{ width: '80%' }}>
        New Employee
        <CloseIcon
          onClick={() => handleAddClose()}
          style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px' }}
        />
      </DialogTitle>
      <DialogContent style={{ paddingBottom: '15px' }}>
        {/* Add a new employee to your account */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="First Name"
                  fullWidth
                  id="firstname"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="Last Name"
                  fullWidth
                  id="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                <Button
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={() => onSendInvite()}
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  Send Invite
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
    </Dialog>
  );
};


export const EditEmployee = (props) => {
  const [showTextField, setShowTextField] = useState(false);
  // const handlePasswordButtonClick = () => {
  //   setShowTextField(true);
  // };

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    // password,
    // setPassword,
    roleId,
    setRoleId,
    editOpen,
    handleEditClose,
    onEditSave,
  } = props;

  return (
    <Dialog
      open={editOpen}
      onClose={handleEditClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1500px",  // Set your width here
          },
        },
      }}
    >
      <DialogTitle style={{ width: '80%' }}>
        Edit Employee
        <CloseIcon
          onClick={() => handleEditClose()}
          style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px' }}
        />
      </DialogTitle>
      <DialogContent style={{ paddingBottom: '15px' }}>
        {/* Add a new employee to your account */}
        {/* <Box> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              {/* <TableCell>Password</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="First Name"
                  fullWidth
                  id="firstname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="Last Name"
                  fullWidth
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  id="email"
                  value={email}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </TableCell>
              <TableCell>
                <Select
                  // value="Employee"
                  value={roleId}
                  // value={getRoleById(roleId)}
                  // value={customer.Role}
                  onChange={(e) => setRoleId(e.target.value)}
                  // onchange={setRoleId}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Role' }}
                >
                  <MenuItem value={1}>Employee</MenuItem>
                  <MenuItem value={2}>Admin</MenuItem>
                </Select>
              </TableCell>
              {/* <TableCell>
                <div>
                  {!showTextField && (
                  <Button onClick={handlePasswordButtonClick}>
                    Reset Password
                  </Button>
                  )}
                  {showTextField && (
                    <TextField
                      variant="outlined"
                      label="Password"
                      fullWidth
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  )}
                </div>
                </TableCell> */}
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                <Button
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={() => onEditSave()}
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  Save Changes
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        {/* </Box> */}
      </DialogContent>
    </Dialog>
  );
};


EmployeesTable.propTypes = {
  items: PropTypes.array,
  getRoleById: PropTypes.func,
  handleEditOpen: PropTypes.func,
  handleDelete: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  fetchData: PropTypes.func,
  // selected: PropTypes.array,
  isAdmin: PropTypes.func,
};


AddEmployee.propTypes = {
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  // setPassword: PropTypes.func,
  // addOpen
  handleAddClose: PropTypes.func,
  onSendInvite: PropTypes.func,
};


EditEmployee.propTypes = {
  // firstName:
  setFirstName: PropTypes.func,
  // lastName:
  setLastName: PropTypes.func,
  // email,
  setEmail: PropTypes.func,
  // password,
  // setPassword: PropTypes.func,
  // roleId,
  setRoleId: PropTypes.func,
  // editOpen: ,
  handleEditClose: PropTypes.func,
  onEditSave: PropTypes.func,
};
