import React, { useState } from "react";
import Styles from "../resources/styles";
import { ReactComponent as PenIcon } from "../resources/pen.svg";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function LaunchScreenContent({ onCancel, onSave }) {
  const [selectedColour, setSelectedColour] = useState("#b0a223"); // Default color
  const textColor = selectedColour === "#ffffff" ? "black" : "white";

  const handleChangeComplete = (color) => {
    setSelectedColour(color.hex);
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox />
        </div>
        <div style={Styles.sectionTitle}>Background</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Colour
            <ColourBox />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LaunchScreenContent;
