import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./navbar.js";
import DesignSidePanel from "../components/myapp/designsidepanel.js";
import AppDesignRenderer from "../components/myapp/appdesignrenderer.js";
import iPhoneImage from "../components/myapp/resources/iphone.png";

export default function MyApp() {
  const [width, setWidth] = useState("20%");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1720);
  const [designManager] = useState(() => ({
    design: {
      navBarBackgroundColor: "#4A90E2",
      navBarSelectedColor: "#ffffff",
      navBarDeselectedColor: "#000000",
    },
    listeners: [],
    getCurrentDesign() {
      return this.design;
    },
    updateDesign(newDesign) {
      this.design = { ...this.design, ...newDesign };
      this.notifyListeners();
    },
    addChangeListener(callback) {
      this.listeners.push(callback);
    },
    removeChangeListener(callback) {
      this.listeners = this.listeners.filter(
        (listener) => listener !== callback
      );
    },
    notifyListeners() {
      this.listeners.forEach((listener) => listener());
    },
  }));

  const handleColorChange = (colorType) => (newColor) => {
    console.log(`${colorType} changed to:`, newColor);
    designManager.updateDesign({ [colorType]: newColor }); // Update the specific color
  };

  const navigate = useNavigate();

  // Fetch company and login data from local storage
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));
  const loginData = JSON.parse(localStorage.getItem("loginData"));

  useEffect(() => {
    if (!companyDetails || !loginData) {
      console.log("Redirecting to /Login");
      navigate("/Login");
    }
  }, [companyDetails, loginData, navigate]);

  useEffect(() => {
    const handleResize = () => {
      const isLarge = window.innerWidth >= 1720;
      setIsLargeScreen(isLarge);
    };

    // Debouncing the resize event
    const debounceResize = () => {
      let resizeTimeout;
      return () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(handleResize, 200);
      };
    };

    const debouncedResizeHandler = debounceResize();

    // Initial check
    handleResize();

    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, []);

  const handleWidthChange = (newWidth) => {
    setWidth(newWidth);
  };

  if (!companyDetails) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar name="My App">
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <div
          style={{
            height: isLargeScreen ? "800px" : "570px",
            width: isLargeScreen ? "388px" : "286px",
            position: "relative",
            marginLeft: width === "4%" ? "35%" : "20%",
            transition:
              "margin-left 0.1s ease, height 0.1s ease, width 0.1s ease",
            display: "flex",
            marginTop: "5%",
            justifyContent: "center",
            //backgroundColor: "#000000",
            alignItems: "center",
          }}
        >
          <img
            src={iPhoneImage}
            alt="iPhone"
            draggable="false" // Prevents dragging the image
            onDragStart={(e) => e.preventDefault()} // Prevents dragging via JavaScript
            style={{
              position: "absolute",
              width: "99%",
              height: "100%",
              objectFit: "contain",
              zIndex: 2,
              transition: "width 0.1s ease, height 0.1s ease",
              userSelect: "none", // Prevents selecting the image
              pointerEvents: "none",
            }}
          />
          <AppDesignRenderer
            designManager={designManager}
            isLargeScreen={isLargeScreen}
          />
        </div>
        <DesignSidePanel
          onWidthChange={handleWidthChange}
          designManager={designManager}
        />
      </div>
    </Navbar>
  );
}
