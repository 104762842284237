import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SettingsIcon from "@mui/icons-material/Settings";
import cursorImage from "../../components/myapp/resources/cursor.png";

// Import the content components
import HomeContent from "./uicontent/HomeContent";
import CardContent from "./uicontent/CardContent";
import OffersContent from "./uicontent/OffersContent";
import SettingsContent from "./uicontent/SettingsContent";

const AppDesignRenderer = ({ designManager, isLargeScreen }) => {
  const [activeTab, setActiveTab] = useState("home");
  const [design, setDesign] = useState(designManager.getCurrentDesign());

  useEffect(() => {
    const handleDesignChange = () => {
      setDesign(designManager.getCurrentDesign());
      console.log("[Design Manager]: ", designManager.getCurrentDesign());
    };

    designManager.addChangeListener(handleDesignChange);

    return () => {
      designManager.removeChangeListener(handleDesignChange);
    };
  }, [designManager]);

  // Function to render content based on the active tab using components
  const getSelectedTabContent = () => {
    switch (activeTab) {
      case "home":
        return <HomeContent />;
      case "card":
        return <CardContent />;
      case "offers":
        return <OffersContent />;
      case "settings":
        return <SettingsContent />;
      default:
        return <div>Content for {activeTab} tab</div>;
    }
  };

  // Function to render the bottom navbar with MUI icons
  const renderNavBar = () => {
    const tabs = [
      { name: "home", icon: <HomeIcon /> },
      { name: "card", icon: <CreditCardIcon /> },
      { name: "offers", icon: <LocalOfferIcon /> },
      { name: "settings", icon: <SettingsIcon /> },
    ];

    return (
      <Box
        sx={{
          height: "65px",
          backgroundColor: design.navBarBackgroundColor,
          width: "100%",
          position: "absolute",
          bottom: 3,
          left: 0,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          borderRadius: "0 0 30px 30px",
          padding: "0 10px",
        }}
      >
        {tabs.map((tab) => (
          <Box
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            sx={{
              display: "flex",
              padding: "8px 15px", // Add padding for larger touch target
              //backgroundColor: "#000000",
              flexDirection: "column",
              alignItems: "center",
              color:
                activeTab === tab.name
                  ? design.navBarSelectedColor
                  : design.navBarDeselectedColor,
            }}
          >
            {tab.icon}
            <Typography
              sx={{
                color:
                  activeTab === tab.name
                    ? design.navBarSelectedColor
                    : design.navBarDeselectedColor,
                fontSize: "10px",
                marginTop: "4px",
              }}
            >
              {tab.name.charAt(0).toUpperCase() + tab.name.slice(1)}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const ScreenContainer = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: isLargeScreen ? "40px" : "30px",
          transform: `translate(-50%, -50%) scale(${
            isLargeScreen ? 1.2 : 0.9
          })`,
          height: isLargeScreen ? "630px" : "610px",
          width: isLargeScreen ? "288px" : "278px",
          //backgroundColor: design.primaryBackgroundColor,
          overflow: "hidden",
          transition: "all 0.1s ease",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pointerEvents: "auto", // Ensure pointer events are enabled
          cursor: `url(${cursorImage}) 8 8, auto`, // Set the custom cursor with a hotspot
        }}
      >
        <div
          style={{
            paddingTop: "20%",
            paddingLeft: "5%",
            flex: 1,
            overflowY: "auto",
            color: design.textColor,
          }}
        >
          {getSelectedTabContent()}
        </div>
        {renderNavBar()}
      </div>
    );
  };

  return <ScreenContainer />;
};

export default AppDesignRenderer;
