import { useCallback, useMemo, useState, useEffect } from "react";
import Navbar from "./navbar.js";
import { ApiServiceClass } from "../components/api-service.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EmployeesTable,
  AddEmployee,
  EditEmployee,
} from "../sections/employees-table.js";
// import { CustomersSearch } from "../services/customers-search.js";
// import { applyPagination } from "../services/apply-pagination.js";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { faL } from "@fortawesome/free-solid-svg-icons";


export default function Employees() {
  const [users, setUsers] = useState([]);
  // const [buttonDisabled, setButtonDisabled] = useState(false);

  // selected employee details
  const [employeeId, setEmployeeId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [accountApproved, setAccountApproved] = useState(false);
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState(1);
  const [apiCallFinished, setApiCallFinished] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  // cache details
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  let navigate = useNavigate();

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  function getUserRoleById(reqUserId) {
    const user = users.find(user => user.UserId === reqUserId);
    if (user) {
      return user.Role;
    } else {
      return null; // Or handle the case where the user is not found
    }
  }

  const handleAddOpen = () => {
    if (getUserRoleById(loginData.UserId) == 2) {
      setAddOpen(true);
    }
    else {
      toast.error('User is not an admin')
    }

  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = (item) => {
    setAddOpen(false);
    setEditOpen(!editOpen);
    setEmployeeId(item.UserId);
    setFirstName(item.FirstName);
    setLastName(item.LastName);
    setEmail(item.EmailAddress);
    setAccountApproved(item.AccountApproved);
    setRoleId(item.Role);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };


  // const useCustomers = (page, rowsPerPage) => {
  //   return useMemo(() => {
  //     return applyPagination(users, page, rowsPerPage);
  //   }, [page, rowsPerPage]);
  // };
  // const customers = useCustomers(page, rowsPerPage);
  // const useCustomerIds = (customers) => {
  //   return useMemo(() => {
  //     return customers.map((customer) => customer.id);
  //   }, [customers]);
  // };

  // const customersIds = useCustomerIds(customers);
  // const customersSelection = useSelection(customersIds);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  useEffect(() => {
    fetchData();
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const fetchData = async () => {
    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
        RequesterUserId: loginData.UserId,
      };
      console.log(requestBody);
      const data = await ApiServiceClass.GetStaffMembers(requestBody);
      console.log(data);
      setUsers(data);
      setApiCallFinished(true);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const validateAddSave = () => {
    // Check if firstName is empty
    if (firstName.length === 0) {
      toast.error("Please enter a first name", { autoClose: 1000 });
      return false;
    }
  
    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address", { autoClose: 1000 });
      return false;
    }
  
    return true;
  };

  const onSendInvite = async () => {
    // check valid credentials
    const isValid = validateAddSave();
    if (!isValid) {
      return null;
    }

    // add the staff member to the database
    try {
      var requestBody2 = {
        "CompanyId": companyDetails.Id,
        "FirstName": firstName,
        "LastName": lastName,
        "EmailAddress": email,
        "Password": "Testing123!",
        "RequesterUserId": loginData.UserId,
        "Role": roleId,
      };

      console.log("requestBody2", requestBody2);
      const response = await ApiServiceClass.CreateStaffMember(requestBody2);
      await console.log("response ", response)
      if (response.status >= 200 && response.status < 300) {
      } else {
        if (response.status === 409) {
          toast.error("Email is already in use");
          console.error(`Email is already in use`);
          return false
        } else {
          console.error(`Employees.js Error: ${response.text}`);
          return false
        }
      }
    } catch (error) {
      console.error('CreateStaffMember handling error')
      return false
    }

    // send the email invite
    try {
      var requestBody1 = {
        "CompanyId": companyDetails.Id,
        "Name": firstName,
        "EmailAddress": email
      };
      console.log("requestBody1", requestBody1);
      const response = await ApiServiceClass.SendInviteEmployeeEmail(requestBody1);
      await console.log("response ", response)

      if (response.status >= 200 && response.status < 300) {
        toast.success("Invite Sent!");
      }
      else {
        console.error(`SendInviteEmployeeEmail Employees.js Error: ${response.text}`);
      }
    } catch (error) {
      console.error('SendInviteEmployeeEmail handling error')
    }

    setEmployeeId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setAccountApproved(false);
    setRoleId(1);
    fetchData();
    setAddOpen(false);
  };

  const validateEditSave = () => {
    // Check if firstName is empty
    if (firstName.length === 0) {
      toast.error("Please enter a first name", { autoClose: 1000 });
      return false;
    }
  
    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address", { autoClose: 1000 });
      return false;
    }
  
    // if user tries to make themselves not an non admin
    if (loginData.UserId == employeeId && roleId == 1) {
      toast.error("You can't remove your own admin privileges!", { autoClose: 1000 });
      return false;
    }

    return true;
  };

  const onEditSave = async () => {
    const isValid = validateEditSave();
    console.log(isValid);
    if (isValid) {
      try {
        var requestBody = {
          UserId: employeeId,
          CompanyId: companyDetails.Id,
          FirstName: firstName,
          LastName: lastName,
          EmailAddress: email,
          // Password: password,
          RequesterUserId: loginData.UserId,
          Role: roleId,
        };
        console.log(requestBody);
        const data = await ApiServiceClass.EditStaffMember(requestBody);
        console.log(data);
        toast.success("Employee Saved");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      fetchData();
      handleEditClose();
    }
  };

  const EmployeeTable = (users) => {
    function getRoleById(id) {
      if (id === 1) {
        return "Employee";
      } else if (id === 2) {
        return "Admin";
      } else {
        return "";
      }
    }

    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingTop={4}
          paddingBottom={1}
          spacing={5}
        >
          <div style={{ marginLeft: "auto", marginBottom: "10px" }}>
            <Button
              startIcon={
                <SvgIcon fontSize="small">
                  <PlusIcon />
                </SvgIcon>
              }
              variant="contained"
              onClick={() => handleAddOpen()}
            >
              Create Employee
            </Button>
          </div>
        </Stack>

        {addOpen && (
          <AddEmployee
            firstName={firstName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            // setPassword={setPassword}
            addOpen={addOpen}
            handleAddClose={handleAddClose}
            onSendInvite={onSendInvite}
          />
        )}

        {editOpen && (
          <EditEmployee
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            // password={password}
            // setPassword={setPassword}
            roleId={roleId}
            setRoleId={setRoleId}
            editOpen={editOpen}
            handleEditClose={handleEditClose}
            onEditSave={onEditSave}
          />
        )}
        {!apiCallFinished ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%", // Adjust the height as needed
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <EmployeesTable
            // count={users.length}
            items={users}
            getRoleById={getRoleById}
            requesterId={loginData.UserId}
            handleEditOpen={handleEditOpen}
            handleEditClose={handleEditClose}
            fetchData={fetchData}
          // isAdmin={() => {return roleId === 2}}
          />
        )}
      </>
    );
  };

  return <Navbar name="Employees">{EmployeeTable(users)}</Navbar>;
}
