import React, { useState } from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import TextBox from "./sharedcomponents/TextBox";
import NumberBox from "./sharedcomponents/NumberBox";
import StampBox from "./sharedcomponents/StampBox";
import { stamps } from "../../../tools/stamps.js";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function RewardContent({ onCancel, onSave }) {
  // Move useState inside the functional component
  const [stampName, setStampName] = useState("scissors");
  const [stampColor, setStampColor] = useState("#0260fe");
  const [stampBorderColor, setStampBorderColor] = useState("#ff0000");
  const [panelBackgroundColor, setPanelBackgroundColor] = useState("#ff0000");

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Reward Details</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Title
            <TextBox defaultText="Enter title" />
          </div>
          <div style={Styles.gridItemRight}>
            Subtitle
            <TextBox defaultText="Enter subtitle" />
          </div>
          <div style={Styles.gridItemLeft}>
            Purchases Required
            <NumberBox />
          </div>
        </div>
        <div style={{ ...Styles.contentGrid, marginTop: "-4%" }}>
          <div style={Styles.gridItemLeft}>
            Text Colour
            <ColourBox position={"left"} />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Stamp Details</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Icon
            <StampBox
              stamps={stamps}
              stampName={stampName}
              setStampName={setStampName}
            />
          </div>
          <div style={Styles.gridItemRight}>
            Colour
            <ColourBox color={stampColor} onChange={setStampColor} />
          </div>
          <div style={Styles.gridItemLeft}>
            Border Colour
            <ColourBox
              color={stampBorderColor}
              onChange={setStampBorderColor}
            />
          </div>
          <div style={Styles.gridItemRight}>
            Panel Background Colour
            <ColourBox
              color={panelBackgroundColor}
              onChange={setPanelBackgroundColor}
            />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default RewardContent;
