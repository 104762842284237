import React from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function LoyaltyContent({ onCancel, onSave }) {
  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Colours</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Text Colour
            <ColourBox />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LoyaltyContent;
