import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./navbar.js";
import { ApiServiceClass } from "../components/api-service.js";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import iPhoneImage from "../components/myapp/resources/iphone.png"; // Adjust the path to your actual image file
import SendIcon from '@mui/icons-material/Send';
import { toast } from "react-toastify";
import { StatsMainBox } from "../tools/material-kit/overview/stats-mainbox.js";
import TicketIcon from "@heroicons/react/24/solid/TicketIcon.js";


export default function MarketingPushNotifications() {
  let navigate = useNavigate();

  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);

  const [message, setMessage] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [targetGroupId, setTargetGroupId] = useState(1);
  const [isScheduledNow, setIsScheduledNow] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      if (companyDetails === null || loginData === null) {
        console.log("redirecting to /Login");
        navigate("/Login");
      }
      // await GetBasicDashboardInformation();
    };

    fetchData();
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const listStyle = {
    padding: "15px",
    height: "auto",
    border: "0px solid #ddd",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  };

  const CreatePushNotification = async () => {
    try {
      const requestBody = {
        CompanyId: companyDetails.Id,
        Message: message,
        CustomerGroup: targetGroupId,
        ScheduledTime: scheduledTime
      };
      console.log(requestBody);
      const value = await ApiServiceClass.CreatePushNotification(requestBody);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
    toast.success("Push notification scheduled!");
  };

  const headerStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#171663",
  };

  function scheduleNotification() {
    const selectedDate = new Date(scheduledTime); // Parse the selected scheduled time
    const currentDate = new Date(); // Get the current date and time

    // Check if the selected date is valid and in the future
    if (!scheduledTime || selectedDate <= currentDate) {
      toast.error("Please select a valid future date and time.");
      return;
    }

    if (message == "") {
      toast.error("Please add a message.");
      return;
    }

    setMessage("")

    // If the date is valid, proceed with scheduling the notification
    CreatePushNotification();
  }

  const handleNowChange = (e) => {
    setIsScheduledNow(e.target.checked);
    if (e.target.checked) {
      setScheduledTime(new Date().toISOString().slice(0, 16)); // Set to current time
    }
  };

  // Simulating fetching notifications
  const fetchedNotifications = [
    {
      CreatedDateTime: "2024-10-11 10:00:00",
      Message: "Sample notification 1",
      CustomerGroup: "Everyone",
      ScheduledTime: "2024-10-12 09:00:00",
      Sent: true,
    },
    {
      CreatedDateTime: "2024-10-10 08:30:00",
      Message: "Sample notification 2",
      CustomerGroup: "Specific group",
      ScheduledTime: "2024-10-13 12:00:00",
      Sent: false,
    },
  ];

  return (
    <Navbar name="marketing-push-notifications" title="push-notifications">
      <div style={{ width: "95%", marginLeft: "2%", marginTop: "4%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* Left Section - New Notification and Notification History */}
          <div style={{ flex: 1, marginRight: "2%", }}>
            <Card style={{ marginBottom: "20px", width: "350px" }}>
              <StatsMainBox
                title="Your credits"
                tooltipText="Your credits shows the number of credits available this subscription month"
                filterRange="Renews 12/12/2025"
                icon={TicketIcon}
                iconColour="#3265a8"
                // comparedToPreviousWeek={"10"}
                sx={{ height: "100%" }}
                value="1"
              />
            </Card>
            <div style={{ ...listStyle, marginBottom: "20px", }}>
              <div style={headerStyle}>New Push Notification</div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '16px', margin: "10px" }}>
                <div>
                  Target group
                </div>
                <Select
                  value={targetGroupId}
                  onChange={(e) => setTargetGroupId(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Target Group' }}
                  style={{ minWidth: '150px', textAlign: 'left' }}>
                  <MenuItem value={1}>Everyone</MenuItem>
                </Select>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '16px', margin: "10px", }}>
                <div>
                  Scheduled Time
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', maxWidth: '300px' }}>
                  <input
                    type="datetime-local"
                    id="datetime"
                    name="datetime"
                    value={scheduledTime}
                    onChange={(e) => setScheduledTime(e.target.value)}
                    disabled={isScheduledNow} // Disable the input if "Now" is checked
                    style={{
                      padding: '10px',
                      fontSize: '16px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: isScheduledNow ? '#e0e0e0' : '#f9f9f9', // Change background color when disabled
                      color: '#333',
                    }}
                  />
                  <label style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      checked={isScheduledNow}
                      onChange={handleNowChange} // Update "Now" checkbox
                      style={{ marginRight: '5px' }}
                    />
                    Now
                  </label>
                </div>
              </div>

              <TextField
                margin="normal"
                required
                id="message"
                label="Message"
                type="text"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={3}
                style={{ width: "100%" }}
              />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  onClick={scheduleNotification}
                  style={{ cursor: 'pointer', marginRight: '8px', width: '40%' }}
                >
                  Schedule Notification
                </Button>
              </div>
            </div>


            <div style={listStyle}>
              <div style={headerStyle}>Push Notifications</div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Created DateTime</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Customer Group</TableCell>
                    <TableCell>Scheduled Time</TableCell>
                    <TableCell>Sent</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchedNotifications.map((notif) => (
                    <TableRow hover key={notif.UserId}>
                      <TableCell>{notif.CreatedDateTime}</TableCell>
                      <TableCell>{notif.Message}</TableCell>
                      <TableCell>{notif.CustomerGroup}</TableCell>
                      <TableCell>{notif.ScheduledTime}</TableCell>
                      <TableCell>{notif.Sent ? "Yes" : "No"}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>

          {/* Right Section - App Image */}
          < div
            style={{
              flex: 0.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={iPhoneImage}
              alt="iPhone"
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
              style={{
                width: "70%",
                maxHeight: "100%",
                userSelect: "none",
              }}
            />
          </div>
        </div>
      </div>
    </Navbar>
  );
}
